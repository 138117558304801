<template>
  <div>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
    <!-- general tab -->
    <b-tab active style="width:100%;">
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('others.general') }}</span>
      </template>

      <account-general-form></account-general-form>
    </b-tab>

    <!-- change password tab -->
    <b-tab style="width:100%;">
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t('others.change_password') }}</span>
      </template>

      <account-password-form></account-password-form>
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {},
    }
  },
}
</script>

